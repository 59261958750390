<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid pt-2">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      ></app-text-editor>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'details'"
        label="Details"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.details"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-attachment-input
        [label]="'Attachments'"
        [control]="formGroup?.controls?.attachments"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
        [dragAndDropMode]="true"
        [multiple]="true"
        [uploadLocation]="'CODE'"
        [useGlobalPasteEventListener]="false"
        [staticUploadWidth]="false"
      ></app-attachment-input>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && (data?.status == 'DRAFT' || !data?.status)) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton && (data?.status == 'DRAFT' || !data?.status)) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton && (data?.status == 'DRAFT' || !data?.status)) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
