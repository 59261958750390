import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, DataTypeEnum, GuidelineDto, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-guideline-item-form',
  templateUrl: './guideline-item-form.component.html',
  styleUrl: './guideline-item-form.component.scss',
})
export class GuidelineItemFormComponent extends BaseForm<GuidelineDto> implements OnInit {
  dataTypeEnum = DataTypeEnum;
  constructor(
    public viewModeService: ViewModeService,
    private router: Router
  ) {
    super(viewModeService, ModuleKeywords.Guideline);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
          ...this.getDataKeyValueFormat(),
          linkedDocuments: this.formGroup?.controls?.attachments?.value
            ? [...this.formGroup.controls.attachments.value]
            : [],
        }
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      details: new FormControl(null),
      attachments: new FormControl(null),
    });
  }
  // resetFormGroup() {
  //   this.formGroup.reset();
  // }
}
